import * as React from 'react';

import {
	MouseEvent,
	MutableRefObject,
	LegacyRef,
	RefObject,
	useEffect,
	useRef,
	useState,
} from 'react';
import {
	useTranslation,
} from 'react-i18next';

// ENUMS
import {
	EnumFormMode,
	EnumInputSize,
} from '@enums/form.enum';
import {
	EnumComponentType,
} from '@enums/component.enum';
import {
	EnumFontStyle,
} from '@enums/font.enum';
import {
	EnumTheme,
} from '@enums/theme.enum';

// HOC
import withDropdown from '@hoc/withDropdown';

// COMPONENTS
import BadgeCounter from '@components/badge-counter';
import Dropdown from '@components/dropdown';
import {
	DropdownItemProps,
} from '@components/dropdown/dropdown-item';
import InputLabel from '@components/form/input-label';
import {
	InputProps,
	InputPropsCommon,
} from '@components/form/input';
import LabelInput from '@components/form/input-label';

// STYLES
import styles from './input-select.module.scss';
import formUtils from '@modules/formUtils';

export enum DropdownSize {
	DEFAULT = 'default',
	FULL = 'full',
}
interface itemProps {
	text?: string;
}

interface currentProps {
	options?: Array<itemProps>;
	values?: string[] | number[];
	value?: string | number;
	index?: itemProps;
}

interface HTMLSelectElementCustom extends HTMLSelectElement {
	values: Array<string>;
}

type registerHTMLSelect = Partial<{
	current: LegacyRef<currentProps>;
	hookRef: MutableRefObject<currentProps>;
	name?: string;
	rules?: object;
	ref?: RefObject<HTMLSelectElementCustom>;
	pattern?: {
		value?: RegExp | string;
		message?: string;
	};
}>;

interface HTMLSelectElementCustom extends HTMLSelectElement {
	values: string[];
}

interface MethodsProps {
	register?: (name: string, rules: object) => registerHTMLSelect;
	formState?: {
		errors?: {
			[key: string]: string;
			name?: string;
		};
	};
	setError?: (name?: string) => void;
	setValue?: (name: string, value: unknown) => void;
	trigger?: (name: string) => void;
	clearErrors?: (name?: string) => void;
}

interface InputSelectProps extends InputPropsCommon {
	classNameDropdown?: string;
	dropdownIsActive?: boolean;
	dropdownSize?: DropdownSize;
	hasEllipsis?: boolean;
	iconName?: string;
	isMultiSelect?: boolean;
	innerRef?: RefObject<HTMLSelectElementCustom>;
	methods?: MethodsProps;
	onClick?: (event: MouseEvent<HTMLElement>) => void;
	onClickOption?: (event: MouseEvent<HTMLElement>) => void;
	onClickOutside?: (event: MouseEvent<HTMLElement>) => void;
	options: DropdownItemProps[];
	register?: registerHTMLSelect;
	registerRules?: object;
	resetSelectedIndexes?: boolean;
	selectedIndex?: number;
	selectedIndexes?: Array<number>;
	size?: EnumInputSize;
	style?: {
		bottom?: string;
		left?: string;
		right?: string;
		top?: string;
	};
	text?: string;
}

const InputSelect = ({
	className,
	customError,
	'data-testid': dataTestid,
	disabled,
	defaultValue,
	dropdownSize = DropdownSize.DEFAULT,
	hasShadow = false,
	hasEllipsis,
	id,
	isMultiSelect = false,
	innerRef,
	invalid = null,
	label,
	methods,
	name,
	onClick,
	onClickOption,
	onClickOutside,
	options,
	placeholder,
	required,
	resetSelectedIndexes,
	selectedIndex,
	selectedIndexes = [
	],
	size,
	style = {
		top: 'calc(100% + 6px)',
		left: '0'
	},
	tabIndex = 0,
	validationType,
}: InputSelectProps): JSX.Element => {
	const { t } = useTranslation();

	const inlineStyles: InputSelectProps['style'] = {
	};

	if (style?.top) inlineStyles.top = style.top;
	if (style?.right) inlineStyles.right = style.right;
	if (style?.bottom) inlineStyles.bottom = style.bottom;
	if (style?.left) inlineStyles.left = style.left;

	let defaultSelectedValue = Array.isArray(options) && options.length > 0
		? options[0]?.value
		: undefined;
	let defaultSelectedText = Array.isArray(options) && options.length > 0
		? options[0]?.label
		: undefined;
	let selectedIndexInitial = 0;
	const selectedIndexesInitial = [
	] as number[];

	const selectedOption = [
	] as DropdownItemProps[];
	options?.forEach((option, index) => {
		if (option.selected) {
			selectedIndexesInitial.push(index);
			selectedOption.push(option);
			selectedIndexInitial = index;
		}
	});
	if (selectedOption.length) {
		defaultSelectedValue = selectedOption[0].value;
		defaultSelectedText = selectedOption[0].label;
	} else if (selectedIndex !== null && selectedIndex !== undefined) {
		selectedIndexInitial = selectedIndex;
		defaultSelectedValue = options[selectedIndex]?.value;
		defaultSelectedText = options[selectedIndex]?.label;
	}

	if (placeholder) {
		selectedIndexInitial = null;
	}

	const initialDefaultValue = defaultValue ? defaultValue : defaultSelectedValue;

	let displayText;
	if (selectedIndexes?.length > 0) {
		displayText = options[selectedIndexes[0]]?.label || placeholder || defaultSelectedText;
	} else if (selectedIndex !== undefined && selectedIndex !== null) {
		displayText = options[selectedIndex]?.label || placeholder || defaultSelectedText;
	} else {
		displayText = placeholder || defaultSelectedText;
	}

	const initialState: InputSelectProps = {
		resetSelectedIndexes: resetSelectedIndexes,
		selectedIndex: selectedIndexInitial ? selectedIndexInitial : selectedIndex,
		selectedIndexes: selectedIndexesInitial.length ? selectedIndexesInitial : selectedIndexes,
		defaultValue: initialDefaultValue,
		error: methods?.formState?.errors[name] as InputPropsCommon['error'],
		name,
		required,
		dirty: false,
		text: displayText,
		dropdownIsActive: false,
		options: options,
	};

	const [
		state,
		setState
	] = useState(initialState);

	useEffect(() => {
		if (resetSelectedIndexes) {
			setState({
				...initialState,
				text: selectedIndexes?.length > 0
					? options[selectedIndexes[0]]?.label
					: (selectedIndex !== undefined && selectedIndex !== null
						? options[selectedIndex]?.label
						: placeholder || defaultSelectedText),
			});
		}
	}, [
		resetSelectedIndexes
	]);

	const isControled = methods?.register;
	const registerRules = {
	} as InputProps['registerRules'];
	let register = null;

	if (isControled) {
		if (validationType && required) {
			registerRules.required = {
				value: true,
				message: t('general.form.input.error.select')
			};

			registerRules.pattern = {
				value: formUtils.rulesByType['default'],
				message: t('general.form.input.error.select')
			};
		}
		register = methods.register(name, registerRules);
	}

	const hookRef = useRef();
	const localRef = innerRef || hookRef;
	let select = localRef.current as HTMLSelectElementCustom;

	useEffect(() => {
		select = localRef.current;
	}, [
		localRef
	]);

	const classes = [
		styles.select_with_dropdown
	];

	if (className) classes.push(className);
	const inputSelectClasses = [
		styles.input_select
	];

	const triggerClasses = [
		styles.trigger
	];
	if (placeholder && state.text === placeholder) triggerClasses.push(styles.placeholder);
	if (disabled) triggerClasses.push(styles.disabled);
	if (hasShadow) triggerClasses.push(styles.shadow);
	if (size) triggerClasses.push(styles[`${'size__' + size}`]);

	const handleOnClickLabel = (event: MouseEvent<HTMLElement>) => {
		if (onClick) onClick(event);
	};

	// TO_TEST : function can't be tested because it's on a child element and we don't have access to it in tests
	/* istanbul ignore next */
	function getErrorMsg() {
		const errorMsg = (customError ? customError : t('general.form.input.error.select')) as string;

		// ONLY WITH FORM COMPONENT
		/* istanbul ignore next */
		if (methods) {
			methods.setError(name);
		}

		return errorMsg;
	}

	const initialValues: string[] = [
	];

	/* istanbul ignore next */
	if (select && options?.length) {
		if (isMultiSelect && state.selectedIndexes.length) {
			state.selectedIndexes.forEach((optionIndex: number) => {
				initialValues.push(options[optionIndex].value);
			});
			select.values = initialValues;
		} else {
			select.value = (selectedIndex !== undefined && options[selectedIndex] !== undefined)
				? options[selectedIndex].value
				: null;
		}
	}

	// TO_TEST : function can't be tested because it's on a child element and we don't have access to it in tests
	/* istanbul ignore next */
	const handleOnClickOption = (event: MouseEvent<HTMLElement>, itemData: DropdownItemProps) => {
		let index: number = null;
		let arrayIndex: Array<number> = null;
		let arrayValues = null;

		const options: Array<itemProps> = Array.from(select?.options);
		if (isMultiSelect) {
			if (state?.value && Array.isArray(state.value)) {
				arrayValues = state.value.includes(itemData.value)
					? state.value.filter((value) => value !== itemData.value)
					: [
						...state.value,
						itemData.value
					];
			} else if (state?.defaultValue && Array.isArray(state.defaultValue)) {
				arrayValues = state.defaultValue.includes(itemData.value)
					? state.defaultValue.filter((value) => value !== itemData.value)
					: [
						...state.defaultValue,
						itemData.value
					];
			} else {
				arrayValues = [
					itemData.value
				];
			}
			select.values = arrayValues;

			index = options?.findIndex((item: itemProps) => item.text === itemData.label);
			arrayIndex = state.selectedIndexes.includes(index)
				? state.selectedIndexes.filter(indexElement => indexElement !== index)
				: [
					...state.selectedIndexes,
					index
				];

			if (methods) methods.setValue(name, arrayValues);
		} else {
			select.value = itemData.value;
			arrayValues = itemData.value;
			index = options.findIndex((item: itemProps) => item.text === itemData.label);

			if (methods) methods?.setValue(name, arrayValues);
		}

		let newText = placeholder;
		if (isMultiSelect && arrayIndex.length > 0) {
			newText = options[arrayIndex[0]]?.text;
		} else if (!isMultiSelect && index !== null) {
			newText = options[index]?.text || placeholder || defaultSelectedText;
		}

		const isDirty = validationType !== EnumFormMode.ON_SUBMIT && (state.dirty === false || state.value !== state.initialValue);
		const isInvalid = validationType !== EnumFormMode.ON_SUBMIT && state.required ? !formUtils.isInputValid(select) : false;

		let newState: InputSelectProps = {
			...state,
			dirty: isDirty,
			error: null,
			required: required,
			selectedIndex: isMultiSelect ? undefined : index,
			selectedIndexes: isMultiSelect ? arrayIndex : [
			],
			text: newText,
			value: arrayValues,
			dropdownIsActive: isMultiSelect,
		};

		if (isInvalid) {
			newState = {
				...newState,
				error: {
					message: getErrorMsg()
				},
			};
		}

		if (methods) {
			methods.clearErrors(name);
			if (validationType !== EnumFormMode.ON_SUBMIT) {
				methods.trigger(name);
			}
		}

		setState(newState);

		if (onClickOption) onClickOption(event);
	};

	// TO_TEST : function can't be tested because it's on a child element and we don't have access to it in tests
	/* istanbul ignore next */
	const handleOnClickOutside = (event: MouseEvent<HTMLElement>) => {
		const newState = {
			...state,
			dropdownIsActive: false,
		};
		setState(newState);
		if (onClickOutside) onClickOutside(event);
	};

	let errorMsgText = null;
	/* istanbul ignore next */
	if (typeof state.error === 'object' && state.error?.message) {
		errorMsgText = customError ? customError : state.error.message;
	} else if (typeof invalid === 'object' && invalid?.message) {
		errorMsgText = customError ? customError : invalid?.message;
	}

	const errorMsgElement = errorMsgText ? (
		<div
			className={styles.error}
			data-testid={`${dataTestid}-error`}
			role="alert"
		>
			{errorMsgText}
		</div>
	) : null;

	if (errorMsgText) triggerClasses.push(styles.invalid);

	const dropdownElement = (
		<Dropdown
			data-testid={`${dataTestid}-dropdown`}
			hasEllipsis={hasEllipsis}
			isMultiSelect={isMultiSelect}
			items={options}
			selectedIndex={state.selectedIndex}
			selectedIndexes={state.selectedIndexes}
			size={dropdownSize}
			theme={EnumTheme.SECONDARY}
			onClickItem={handleOnClickOption}
		/>
	);

	const badgeCounterElement = state?.selectedIndexes?.length && isMultiSelect ? (
		<BadgeCounter
			className={styles.badge_counter}
			content={state.selectedIndexes.length}
		/>
	) : null;

	const SelectInput = (
		<select
			aria-invalid={errorMsgText ? 'true' : 'false'}
			className={styles.input}
			data-testid={`${dataTestid}-select`}
			{...register}
			defaultValue={isMultiSelect ? null : state.value || state.defaultValue}
			id={id}
			multiple={isMultiSelect}
			name={name}
			ref={localRef}
			required={required}
		>
			{options?.map((optionItem, index) => {
				return (
					<option
						data-testid={`${dataTestid}-option`}
						key={index}
						value={optionItem.value}
					>{optionItem.label}</option>
				);
			})
			}
		</select>
	);

	const SelectWithDropdown = withDropdown(InputLabel, dropdownElement);
	return (
		<div
			className={classes.join(' ')}
			data-testid={dataTestid}
		>
			{label ? (
				<LabelInput
					className={styles.label}
					data-testid={`${dataTestid}-label`}
					id={id}
					textEllipsis={true}
				>
					{decodeURI(t(label))}
				</LabelInput>
			) : null}
			<SelectWithDropdown
				className={inputSelectClasses.join(' ')}
				classNameDropdown={'dropdown'}
				classNameTrigger={triggerClasses.join(' ')}
				data-testid={`${dataTestid}-selectWithDropdown`}
				disabled={disabled}
				dropdownIsActive={state.dropdownIsActive}
				dropdownSize={dropdownSize}
				iconName='chevron-down'
				iconStyle={EnumFontStyle.LIGHT}
				id={id}
				isMultiSelect={isMultiSelect}
				style={inlineStyles}
				tabIndex={tabIndex}
				onClick={handleOnClickLabel}
				onClickOutside={handleOnClickOutside}
			>
				<>
					{state?.text}
					{badgeCounterElement}
				</>
			</SelectWithDropdown>
			{SelectInput}
			{errorMsgElement}
		</div>
	);
};

InputSelect.displayName = EnumComponentType.INPUT_SELECT;

export {
	InputSelect as default,
	InputSelectProps,
	HTMLSelectElementCustom,
};
