import {
	LayerProps,
} from '@appConf/layers.conf';
import featuresFlagsConf from './featuresFlags.conf';
import {
	EnumQueryMethods,
} from '@enums/query.enum';

// are we in development environment ?
const isDevelopment = process.env.APP_ENV_NAME_FRONTEND === 'development';

type EnumBreakpoints = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

type TypeBreakpointsMap = {
	[key in EnumBreakpoints]: number;
}

const breakPointsMap: TypeBreakpointsMap = {
	xs: 0,
	sm: 360,
	md: 768,
	lg: 1025,
	xl: 1440
};

enum EnumEnvironments {
	DEVELOPMENT = 'development',
	POC = 'poc',
	PRODUCTION = 'production',
	STAGING = 'staging',
	JEST = 'test',
}

enum EnumThemes {
	DARK = 'dark',
	LIGHT = 'light'
}

enum EnumImageFormatQuery {
	USERCARD = '?tr=w-40,h-40,fo-auto',
	CARTO = '?tr=w-240,h-158,c-at_max',
	CARD = '?tr=w-234,h-210,c-at_max',
	CARD_MOBILE = '?tr=w-329,h-180,c-at_max',
	SLIDER = '?tr=w-533,h-376,c-at_max',
	SLIDER_MOBILE = '?tr=w-428,h-286,c-at_max',
	SLIDER_MODAL = '?tr=w-810,h-514,c-at_max',
	SLIDER_MODAL_MOBILE = '?tr=w-390,h-390,c-at_max',
	SQUARE = '?tr=w-40,h-40',
	TABLE_VIEW = '?tr=w-88,h-64,c-fill',
}

interface InterfaceGlobalsApiConf {
	communes: string;
	demand: {
		offers: {
			limit: number;
		};
	};
}

interface InterfaceAutosuggestConf {
	maxNbResults: number;
	minNbInputChars: number;
}

interface InterfaceDebugConf {
	eventEmitter: {
		logs: boolean;
	};
	router: {
		logs: boolean;
	};
}

interface InterfaceHostsConf {
	[key: string]: {
		[key in EnumEnvironments]?: string;
	};
}

interface InterfaceLanguageConf {
	name: string;
	code: string;
	flag: string;
}

interface InterfaceLevenshteinConf {
	ignoreLength: number;
	long: {
		minLength: number;
		distance: number;
	};
	small: {
		minLength: number;
		distance: number;
	};
}

interface InterfaceMapboxConf {
	bounds: {
		maxZoom: {
			mobile: number;
			desktop: number;
		};
		padding: {
			mobile: number;
			desktop: number;
		};
	};
	config: {
		accessToken: string;
		style: string;
		coordinates: number[]; // PARIS
		zoom: number;
	};
	layers?: LayerProps;
}

interface InterfaceTimeoutConf {
	debounce: number;
	mouseleave: number;
	disabled: number;
	hide: number;
	reload_session: number;
	resize: number;
	scroll: number;
	toast: number;
}

interface InterfaceDatadogConf {
	API_KEY: string;
	service: string;
	site: string;
	logs: {
		clientToken: string;
	};
	rum: {
		applicationId: string;
		clientToken: string;
	};
	token?: string;
}

interface InterfaceHasCRMConf {
	isEnabled: boolean;
	name: string;
	authorized_company_ids: number[];
	authorized_usernames: string[];
}

interface InterfaceAppConfVars {
	api: InterfaceGlobalsApiConf;
	autosuggest: InterfaceAutosuggestConf;
	breakpoints: TypeBreakpointsMap;
	debug: InterfaceDebugConf;
	env: EnumEnvironments;
	featureFlags: {
		[key: string]: InterfaceHasCRMConf;
	};
	language_default: InterfaceLanguageConf;
	language_list: InterfaceLanguageConf[];
	levenshtein: InterfaceLevenshteinConf;
	publicPath: string;
	privatePath: string;
	mapbox: InterfaceMapboxConf;
	request: {
		default: {
			method: EnumQueryMethods;
			headers: {
				'Content-type': string;
				'X-Requested-With': string;
			};
		};
	};
	sfLegacyPath: string;
	sfLegacyPathPROD: string;
	sfPath: string;
	sfPathApryse: string;
	sfPathMarketerLogo: string;
	sfPathMesOffres: string;
	sfPathPROD: string;
	theme_default: string;
	theme_list: EnumThemes[];
	timeout: InterfaceTimeoutConf;
	tools: {
		beamer?: {
			product_id: string;
		};
		datadog?: InterfaceDatadogConf;
		google?: {
			tag_manager?: {
				key?: string;
			};
		};
	};
	router: {
		[key: string]: {
			basename: string;
		};
	};
	session?: {
		lifetime: number;
	};
}

const APP_CONF_VARS: InterfaceAppConfVars = {
	api: {
		communes: 'https://geo.api.gouv.fr/communes?format=json&fields=nom,code,centre,population',
		demand: {
			offers: {
				limit: 1500,
			}
		}
	},
	autosuggest: {
		maxNbResults: 8,
		minNbInputChars: 3
	},
	breakpoints: breakPointsMap,
	debug: {
		eventEmitter: {
			logs: isDevelopment
		},
		router: {
			logs: isDevelopment
		}
	},
	env: process.env.APP_ENV_NAME_FRONTEND as EnumEnvironments,
	featureFlags: featuresFlagsConf,
	language_default: {
		name: 'french',
		code: 'fr-FR',
		flag: 'fr'
	},
	language_list: [
		{
			name: 'french',
			code: 'fr-FR',
			flag: 'fr'
		},
		{
			name: 'english UK',
			code: 'en-GB',
			flag: 'gb'
		}
	],
	levenshtein: {
		ignoreLength: 2,
		long: {
			minLength: 8,
			distance: 2
		},
		small: {
			minLength: 0,
			distance: 1
		}
	},
	publicPath: '/app/webapp',
	privatePath: '/app/webapp/api',
	mapbox: {
		bounds: {
			maxZoom: {
				mobile: 10,
				desktop: 20,
			},
			padding: {
				mobile: 50,
				desktop: 200,
			}
		},
		config: {
			accessToken: 'pk.eyJ1IjoiZWRkaGJzIiwiYSI6ImNqd20zcW4yZjAxaHk0YXBmZGtucmdhdjQifQ.EW7D17No8Vhy7NrviHUuXg',
			style: 'mapbox://styles/eddhbs/cl3ed4dp8004u14ndh2w6e6xz',
			coordinates: [
				2.352894817894053,
				48.85635302576033
			], // PARIS
			zoom: 10,
		}
	},
	request: {
		default: {
			method: EnumQueryMethods.GET,
			headers: {
				'Content-type': 'application/json',
				'X-Requested-With': 'XMLHttpRequest',
			}
		}
	},
	router: {
		myoffers: {
			basename: 'app/de/'
		},
		webapp: {
			basename: 'app/webapp/'
		},
	},
	session: {
		lifetime: 1440000 // same as framework.sessions.gc_maxlifetime in folder LPdI/config/packages/framework.yaml
	},
	sfLegacyPath: isDevelopment ? '/LPdI/LPdI_dev.php/fr_FR' : '/LPdI/fr_FR',
	sfLegacyPathPROD: '/LPdI/fr_FR/',
	sfPath: '/app',
	sfPathApryse: '/app/bundles/frontend/webviewer/lib',
	sfPathMarketerLogo: '/app/marketer/logo/',
	sfPathMesOffres: '/de',
	sfPathPROD: '/app/',
	theme_default: 'light',
	theme_list: Object.values(EnumThemes),
	timeout: {
		debounce: 250,
		mouseleave: 250,
		disabled: 20000,
		hide: 500,
		reload_session: 600000,
		resize: 250,
		scroll: 1000,
		toast: 5000
	},
	tools: {
		beamer: {
			product_id: 'JjXJhZqd31237'
		},
		datadog: {
			API_KEY: '7eb73408fbf5a2a04efff08e6542e001',
			service: 'lpdipro_BRUCE',
			site: 'datadoghq.com',
			token: 'pubb4a124b69ba44ba4e4082d5b9f842754',
			logs: {
				clientToken: 'pub5ae61b637565f76233d9b22920b90a53'
			},
			rum: {
				applicationId: 'f54a6662-068c-4ee2-a93a-1521853a4eb2',
				clientToken: 'pub5ae61b637565f76233d9b22920b90a53'
			}
		},
	}
};

const layer = {
	bbox: '{bbox-epsg-3857}',
	key: '3c2b7cf230387dabacaa56d72d74024a',
	root_url: `${process.env.DOMAIN_CARTO}/mapcache/wms`,
	srs: 'EPSG%3A900913',
	tileSize: 256,
};

APP_CONF_VARS.mapbox.layers = layer;

export {
	APP_CONF_VARS,
	EnumEnvironments,
	EnumImageFormatQuery,
	InterfaceAppConfVars,
	InterfaceHostsConf,
};
