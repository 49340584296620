import {
	ObjectKeyStringValueString,
} from '@@types/Settings';

export interface Utils {
	getURL(
		path: string,
		args?: ObjectKeyStringValueString): string;
	getQueryParams(url: string): string[];
}

const getURL = (path: string, args?: ObjectKeyStringValueString): string => {
	let result = `${path}`;

	if (args && Object.keys(args).length) {
		Object.keys(args).forEach(argKey => {
			result = result.replace(argKey, args[argKey]);
		});
	}
	return result;
};

const getQueryParams = (inputString: string = ''): string[] => {
	let queryString = '';
	let queryParams: string[] = [
	];
	if (inputString.includes('?')) {
		queryString = inputString.split('?')[1];
	}
	if (queryString.length && queryString.includes('=')) {
		queryParams = queryParams.concat(queryString.split('&'));
	}

	return queryParams;
};

export {
	getURL,
	getQueryParams,
};
